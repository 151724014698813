export default {
  cs: {
    // registrace
    Registration: {
      cartItemType: 'registration',
      type: 'registration',
      category: 'registration'
    },
    // transfer
    Transfer: {
      cartItemType: 'transfer',
      type: 'registration',
      category: 'transfer',
      excludePack: true
    },
    // emaily
    // IGN_FlexiMail: {
    //   cartItemType: 'email',
    //   type: 'hosting',
    //   category: 'mailhosting',
    //   pricePer: 'period',
    //   staticPrice: '99'
    // },
    Webglobe_Mail_Personal: {
      cartItemType: 'email',
      type: 'hosting',
      category: 'mailhosting',
      pricePer: 'period',
      staticPrice: '39'
    },
    ExchangeOnline: {
      cartItemType: 'email',
      type: 'extra',
      category: 'mailhosting',
      pricePer: 'user_period',
      staticPrice: '137'
    },
    Microsoft365_B_Basic: {
      cartItemType: 'email',
      type: 'extra',
      category: 'mailhosting',
      pricePer: 'user_period',
      customerContact: true,
      staticPrice: '149'
    },
    Microsoft365_B_Standard: {
      cartItemType: 'email',
      type: 'extra',
      category: 'mailhosting',
      pricePer: 'user_period',
      customerContact: true,
      staticPrice: '369'
    },

    // hosting
    WEBG_Host_Start_v2: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'hosting',
      enableTemplateField: false
    },
    WEBG_Host_Plus_v2: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'hosting',
      enableTemplateField: false
    },
    WEBG_Host_Ultra_v2: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'hosting',
      enableTemplateField: false
    },

    // wordpress
    WEBG_WP_Start: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'wp_hosting',
      enableTemplateField: false
    },
    WEBG_WP_Plus: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'wp_hosting',
      enableTemplateField: false
    },
    WEBG_WP_Ultra: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'wp_hosting',
      enableTemplateField: false
    },

    // SSL
    IGN_PositiveSSL: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl',
      enableOrganizationField: true
    },
    IGN_PositiveSSL_WC: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl',
      enableOrganizationField: true
    },
    IGN_PositiveSSL_EV: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl',
      enableOrganizationField: true
    },
    IGN_PositiveSSL_Multi: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl',
      enableOrganizationField: true
    },

    // pojistka
    DOMAIN_INSURANCE: {
      cartItemType: 'safetyGuard',
      type: 'extra',
      category: 'safety-guard'
    },
    // yola
    YOLA_PREMIUM: {
      cartItemType: 'yola',
      type: 'extra',
      category: 'yola'
    },
    YOLA_FREE: {
      cartItemType: 'yola',
      type: 'extra',
      category: 'yola'
    }
  },
  sk: {
    // registrace
    Registration: {
      cartItemType: 'registration',
      type: 'registration',
      category: 'registration'
    },
    // transfer
    Transfer: {
      cartItemType: 'transfer',
      type: 'registration',
      category: 'transfer',
      excludePack: true
    },
    // emaily
    ExchangeOnline: {
      cartItemType: 'email',
      type: 'extra',
      category: 'mailhosting',
      pricePer: 'user_period'
    },
    Microsoft365_B_Basic: {
      cartItemType: 'email',
      type: 'extra',
      category: 'mailhosting',
      pricePer: 'user_period',
      customerContact: true
    },
    Microsoft365_B_Standard: {
      cartItemType: 'email',
      type: 'extra',
      category: 'mailhosting',
      pricePer: 'user_period',
      customerContact: true
    },

    //hosting
    WEBG_Host_Start: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'hosting'
    },
    WEBG_Host_Plus: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'hosting'
    },
    WEBG_Host_Ultra: {
      cartItemType: 'hosting',
      type: 'hosting',
      category: 'hosting'
    },
    // WY_Wordpress_Hosting: {
    //   cartItemType: 'hosting',
    //   type: 'hosting',
    //   category: 'hosting'
    // },
    // WY_Wordpress_Hosting_Extra: {
    //   cartItemType: 'hosting',
    //   type: 'hosting',
    //   category: 'hosting'
    // },
    // WY_Wordpress_Hosting_Multi: {
    //   cartItemType: 'hosting',
    //   type: 'hosting',
    //   category: 'hosting'
    // },
    Webglobe_Mail_Personal: {
      cartItemType: 'email',
      type: 'hosting',
      category: 'mailhosting',
      pricePer: 'period',
      staticPrice: '1.99'
    },
    Webglobe_Mail_Business: {
      cartItemType: 'email',
      type: 'hosting',
      category: 'mailhosting',
      pricePer: 'period',
      staticPrice: '3.99'
    },
    // WY_Registracia_PLUS: {
    //   cartItemType: 'hosting',
    //   type: 'hosting',
    //   category: 'hosting'
    // },

    //Ssl
    WY_SSL_Rapid: { cartItemType: 'sslCert', type: 'extra', category: 'ssl' },
    WY_SSL_Godaddy: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl'
    },
    WY_WC_SSL_Rapid: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl'
    },
    WY_WC_SSL_Thawte: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl'
    },
    WY_EV_SSL_GeoTrust: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl',
      enableOrganizationField: true
    },
    WY_EV_SSL_Symantec: {
      cartItemType: 'sslCert',
      type: 'extra',
      category: 'ssl',
      enableOrganizationField: true
    },

    // pojistka
    DOMAIN_INSURANCE: {
      cartItemType: 'safetyGuard',
      type: 'extra',
      category: 'safety-guard'
    },
    // yola
    YOLA_PREMIUM: {
      cartItemType: 'yola',
      type: 'extra',
      category: 'yola'
    },
    YOLA_FREE: {
      cartItemType: 'yola',
      type: 'extra',
      category: 'yola'
    }
  }
};

export const SERVICE_TYPES = {
  EMAIL: 'email',
  SAFETY_GUARD: 'safetyGuard',
  HOSTING: 'hosting',
  YOLA: 'yola',
  REGISTRATION: 'registration',
  SSL_CERT: 'sslCert',
  TRANSFER: 'transfer'
};

export const SERVICE_EXTRA_CART_ITEMS = {
  [SERVICE_TYPES.REGISTRATION]: [SERVICE_TYPES.SAFETY_GUARD]
};

export const GROUP_ONE_ALLOWED_TLDS = ['com'];
